const proto = "https";
const domain = "www.mahjong-jp.com/sgtest";
const pureUrl = `${proto}://${domain}`;
export const shareConfig = {
  url: encodeURIComponent(pureUrl),
  domain: domain,
  pureUrl: pureUrl,
  url_cn: encodeURIComponent("https://www.lizhimahjong.com/sgtest"),
  tag_1: "一番街シュタゲコラボ",
  tag_2: "麻雀一番街",
  tag_3: "シュタゲ",
  // via: "", // Twitter account 未使用该参数
};
