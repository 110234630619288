<template>
  <div v-if="refresh" :class="['Sgtest_wrap', pageState.Entrance]">
    <div v-if="isNotStart && !isAllowAccess">
      <Permission @pass="isAllowAccess = true"></Permission>
    </div>
    <div v-else-if="isExpried">
      <Expired></Expired>
    </div>
    <div v-else id="screenshot_node" class="Sgtest" :class="[currentState, $i18n.locale]" :style="{ height: pageHeight }">
      <Header :isScreenshot="isScreenshot" />
      <main :style="{ height: `calc(${pageHeight} - 9rem)` }">
        <Entrance v-if="currentState === pageState.Entrance" @startTest="startTest" @denyTest="denyTest" @sendLog="sendLog"></Entrance>
        <EasterEgg v-if="currentState === pageState.EasterEgg" @startTest="startTest" @sendLog="sendLog"></EasterEgg>
        <StartTest v-if="currentState === pageState.StartTest" @toDoTest="toDoTest" @sendLog="sendLog"></StartTest>
        <DoTest v-if="currentState === pageState.DoTest" :answeredQuest="answeredQuest" @toResult="toResult" @sendLog="sendLog"></DoTest>
        <Result
          v-if="currentState === pageState.Result"
          :selectedAnswerList="selectedAnswerList"
          :userInfo="userInfo"
          :isScreenshot="isScreenshot"
          :passCount="passCount"
          @retest="retest"
          @sendLog="sendLog"
          @screenshot="screenshot"
          @screenshoted="screenshoted"
        ></Result>
      </main>
      <img v-if="currentState === pageState.Result" src="@/assets/image/sgtest/result/labmem_img_bg.png" alt="" class="result_bg" />
      <div class="copyright">©Formirai Co., Ltd. ©MAGES./NITRO PLUS</div>
    </div>
  </div>
</template>

<script>
import Header from "./comp/Header.vue";
import Entrance from "./comp/Entrance.vue";
import EasterEgg from "./comp/EasterEgg.vue";
import StartTest from "./comp/StartTest.vue";
import DoTest from "./comp/DoTest.vue";
import Result from "./comp/Result.vue";
import Permission from "./comp/Permission.vue";
import Expired from "./comp/Expired.vue";
import { pageState } from "./utils/data";
import SlsTracker from "@aliyun-sls/web-track-browser";
import { logConfig } from "./utils/logConfig";
import { sha256, isIOS } from "./utils/utils";
import { activityID } from "./utils/data";
import { webShareActivityInfo, webShareActivitySubmit, webSendFlowLog } from "./utils/api";
import DeviceDetector from "device-detector-js";
import Fingerprint2 from "fingerprintjs2";
export default {
  name: "Sgtest",
  components: {
    Header,
    Entrance,
    EasterEgg,
    StartTest,
    DoTest,
    Result,
    Permission,
    Expired,
  },
  data() {
    return {
      refresh: true,
      isAllowAccess: false,
      isScreenshot: false,
      pageState,
      currentState: pageState.Entrance,
      tracker: null,
      logParams: null,
      pageHeight: "100vh",
      answeredQuest: [],
      selectedAnswerList: [],
      userInfo: {
        nickname: "",
        score: 0,
      },
      passCount: 0,
      submitLoading: false,
      buttons: [],
    };
  },
  computed: {
    activity() {
      return this.$store.state.activityList.find((item) => item.activityId === activityID);
    },
    isExpried() {
      if (!this.activity) return false;
      return this.$store.state.serverTime > this.activity.receiveEndTime;
    },
    isNotStart() {
      if (!this.activity) return true;
      return this.$store.state.serverTime < this.activity.receiveStartTime;
    },
  },
  created() {
    this.setHeight();
  },
  mounted() {
    this.initSendLog();
    this.getActivityInfo();
    window.addEventListener("resize", this.handleRefresh);
    this.addEventListeners();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleRefresh);
    this.removeEventListeners();
  },
  watch: {
    currentState: {
      handler() {
        // alert("watch currentState");
        this.removeEventListeners();
        this.$nextTick(() => {
          this.addEventListeners();
        });
      },
    },
  },
  methods: {
    handleRefresh() {
      this.setHeight();
      // this.refresh = false;
      // this.$nextTick(() => {
      //   this.refresh = true;
      //   this.setHeight();
      // });
    },
    setHeight() {
      this.pageHeight = `${window.innerHeight}px`;
    },
    async initSendLog() {
      // 日志对象
      this.tracker = new SlsTracker(logConfig);
      // 设备检测
      const deviceDetector = new DeviceDetector();
      const device = deviceDetector.parse(navigator.userAgent);
      const fingerprint = await this.createBrowserFinger();
      this.logParams = {
        platform: "10",
        os_ver: `${device.device.model} (${device.device.brand})`,
        os: device.os.name,
        fingerprint,
        f1: "10001",
        f2: "0",
      };
      if (this.$route.query.uid && this.$route.query.token && this.$route.query.uid.length < 20) {
        this.logParams.user_id = this.$route.query.uid;
      }
      if (this.currentState !== pageState.Entrance) return;
      this.sendLog({
        f3: "1",
        f4: "0",
        f5: "0",
      });
    },
    startTest(isEasterEggPage) {
      const f4 = isEasterEggPage ? "1" : "0";
      this.currentState = pageState.StartTest;
      // 首页点击开始测试 1,0,1 ; 彩蛋页点击开始测试 1,1,1
      this.sendLog({
        f3: "1",
        f4: f4,
        f5: "1",
      });
    },
    denyTest() {
      this.currentState = pageState.EasterEgg;
      this.sendLog({
        f3: "1",
        f4: "0",
        f5: "2",
      });
    },
    toDoTest(nickname) {
      this.currentState = pageState.DoTest;
      this.userInfo.nickname = nickname;
      this.sendLog({
        f3: "0",
        f4: "0",
        f5: "2",
      });
    },
    async toResult(selectedAnswerList) {
      console.log("selectedAnswerList:", selectedAnswerList);
      this.currentState = pageState.Result;
      this.selectedAnswerList = selectedAnswerList;

      this.submitAnswer();
    },
    async submitAnswer() {
      const uid = this.$route.query.uid;
      const userID = Number(uid) || undefined;
      const score = this.selectedAnswerList.reduce((acc, { score }) => acc + score, 0);
      this.userInfo.score = score;
      if (!userID && score < 60) return; // 无uid且分数小于60分不提交
      const token = this.$route.query.token || undefined;
      this.submitLoading = true;
      try {
        const res = await webShareActivitySubmit({
          activityID,
          userID,
          token,
          score,
          questionIds: this.selectedAnswerList.map((item) => item.qid),
        });
        this.passCount = res.data.data.behindNum;
      } catch (err) {
        console.error("submitAnswer error:", err);
      } finally {
        this.submitLoading = false;
      }
    },
    retest() {
      this.sendLog({
        f3: "0",
        f4: "1",
        f5: "4",
      });
      this.currentState = pageState.StartTest;
      this.getActivityInfo();
    },
    sendLog(log) {
      const logGather = { ...this.logParams, ...log };
      if (process.env.NODE_ENV === "development") {
        console.log("log:", logGather);
      }
      this.sendLogToServer(log);
      this.tracker.send({
        ...this.logParams,
        ...log,
      });
    },
    screenshot() {
      this.isScreenshot = true;
    },
    screenshoted() {
      this.isScreenshot = false;
    },
    async getActivityInfo() {
      const uid = this.$route.query.uid;
      const userID = Number(uid);
      if (!userID) return;
      const token = sha256(uid);
      const res = await webShareActivityInfo({
        activityID,
        userID,
        token,
      });
      console.log("res:", res);
      if (res.data.code !== 0) return;
      this.answeredQuest = res.data.data.finishQuestions;
    },
    createBrowserFinger() {
      return new Promise((resolve, reject) => {
        Fingerprint2.get((components) => {
          // 参数只有回调函数时，默认浏览器指纹依据所有配置信息进行生成
          const values = components.map((component) => component.value); // 配置的值的数组
          const murmur = Fingerprint2.x64hash128(values.join(""), 31); // 生成浏览器指纹
          resolve(murmur);
        });
      });
    },
    async sendLogToServer(log) {
      let toServerLog;
      if (log.f3 === "1" && log.f4 === "0" && log.f5 === "0") {
        // 首页访问
        toServerLog = { f3: "4", f4: "0", f5: "0" };
      } else if (log.f3 === "3" && log.f4 === "1") {
        // 提交测试
        toServerLog = { f3: "4", f4: "0", f5: "1" };
      } else {
        return;
      }
      console.log("this.logParams:", this.logParams);
      await webSendFlowLog({ ...this.logParams, ...toServerLog });
    },
    /* 点击效果兼容ios浏览器 start */
    addEventListeners() {
      if (!isIOS()) return;
      this.buttons = document.querySelectorAll(".btn_selected");
      this.buttons.forEach((btn) => {
        btn.addEventListener("touchstart", this.handleTouchStart);
        btn.addEventListener("touchend", this.handleTouchEnd);
      });
    },
    removeEventListeners() {
      this.buttons.forEach((btn) => {
        btn.removeEventListener("touchstart", this.handleTouchStart);
        btn.removeEventListener("touchend", this.handleTouchEnd);
      });
    },
    handleTouchStart(event) {
      event.target.classList.add("active_effect");
    },
    handleTouchEnd(event) {
      event.target.classList.remove("active_effect");
    },
    /* 点击效果兼容ios浏览器 end */
  },
};
</script>
<style lang="scss" scoped>
// .Sgtest_wrap {
//   width: 100%;
// }
.Sgtest {
  position: relative;
  width: 100%;
  max-width: 75rem;
  margin: 0 auto;
  overflow: hidden;
  background-image: url("../../assets/image/sgtest/entrance/labmemtest_home_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-color: #000;
  &.EasterEgg {
    main {
      align-items: flex-end;
      // padding-bottom: 10rem;
    }
  }
  &.StartTest,
  &.DoTest {
    background-image: url("../../assets/image/sgtest/start_test/labmem_img_bg.png");
    main {
      padding-bottom: 19rem;
    }
  }
  &.Result {
    // background-image: url("../../assets/image/sgtest/result/labmem_img_bg.png");

    main {
      position: relative;
      padding-bottom: 14rem;
      z-index: 1;
    }
  }
  .result_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  main {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }
  .copyright {
    position: absolute;
    left: 0;
    bottom: 1rem;
    z-index: 9;
    width: 100%;
    font-size: 2rem;
    color: #ffffff;
    opacity: 0.8;
    text-align: center;
  }
}
// @media screen and (min-width: 750px) {
//   .Sgtest {
//     width: 100rem;
//     max-width: 100%;
//   }
// }
</style>
<style lang="scss">
.Sgtest {
  * {
    // font-family: Source Han Sans-bold;
    font-family: "Source Han Sans-bold", "Source Han Sans", "blackHanSans", "NotoSansJP-Bold";
    font-weight: normal;
    margin: 0;
    padding: 0;
    p {
      margin: 0;
      padding: 0;
    }
  }
  &.jp {
    * {
      font-family: "NotoSansJP-Bold", "Source Han Sans", "blackHanSans", "Source Han Sans-bold";
    }
  }
  &.kr {
    * {
      font-family: "SourceHanSansKR-Bold", "Source Han Sans", "blackHanSans", "Source Han Sans-bold";
    }
  }
  &.sc {
    * {
      font-family: "SourceHanSansSC-Bold", "Source Han Sans", "blackHanSans", "Source Han Sans-bold";
    }
  }
  .btn_selected {
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &::before {
      content: "";
      display: none;
      width: 14.5rem;
      height: 8.2rem;
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 9;
      transform: translate(-50%, -50%);
      background-image: url("../../assets/image/sgtest/btn_selected.png");
      background-size: cover;
      background-repeat: no-repeat;
    }
    &::after {
      content: "";
      display: none;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 8;
      background-color: rgba(0, 0, 0, 0.1);
      transition: all 0.3s;
    }
    &:active,
    .active_effect {
      &::before {
        display: block;
      }
      &::after {
        display: block;
      }
    }
  }
}
.v-application--wrap {
  background-color: #000;
  min-height: auto;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
}
.Sgtest,
.survey_dialog {
  a,
  img {
    display: block;
    font-family: Source Han Sans-bold;
    font-weight: normal;
  }
  img {
    max-width: 100%;
  }
}
.v-snack {
  .v-snack__wrapper {
    border-radius: 0 !important;
  }
}
</style>
