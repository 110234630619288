<template>
  <div class="DoTest">
    <div class="serial_num_section">
      <div class="serial_num">【{{ currentQuestSeq }}】</div>
      <img src="@/assets/image/sgtest/do_test/line.png" alt="" class="line" />
      <img src="@/assets/image/sgtest/do_test/expression.png" alt="" class="expression" />
    </div>
    <div class="qustion">
      <p :class="$i18n.locale">{{ translate(currentQuestion.question) }}</p>
    </div>
    <div class="options">
      <div
        v-for="(item, index) in currentQuestion.options"
        :key="`${currentQuestion.id}_${item.id}`"
        class="option btn_selected"
        :class="{ active: selectedAnswerId === item.id }"
        @click="selectOption(item)"
      >
        <div class="serial_alphabet notranslate">{{ index | formatSerialAlphabet }}.</div>
        <div class="option_text">{{ translate(item.label) }}</div>
      </div>
    </div>
    <div class="page_info_wrap">
      <div class="page_info">{{ currentQuestSeq }}/10</div>
    </div>
    <div class="btn btn_selected" @click="toNextQuest">
      <img v-if="currentQuestSeq === 10" :src="toFinishText" alt="" class="text finish" />
      <img v-else :src="toNextQuestText" alt="" class="text" />
      <img src="@/assets/image/sgtest/do_test/deco_01.png" alt="" class="deco_01" />
      <img src="@/assets/image/sgtest/do_test/deco_02.png" alt="" class="deco_02" />
    </div>
    <v-snackbar v-model="noSelectTip" :absolute="true" :top="true" :timeout="tipTimeout" class="no_select_tip">
      <div class="no_select_tip_msg">
        {{ translate("sgtest_bg.p56") }}
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import { surveyList, questIdMap } from "../utils/data";
import { translate } from "../../../utils/utils";
export default {
  name: "DoTest",
  props: {
    answeredQuest: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      noSelectTip: false,
      tipTimeout: 3000,
      surveyList,
      questIdMap,
      currentId: null,
      currentQuestSeq: 1,
      selectedAnswerId: null,
      selectedAnswerList: [],
    };
  },
  computed: {
    currentQuestion() {
      if (!this.currentId) {
        return { question: "", options: [] };
      }
      const quest = this.randomSurveyList.find(({ id }) => id === this.currentId);
      return quest;
    },
    randomSurveyList() {
      const easyList = this.getFilteredSurveyList("easy");
      const normalList = this.getFilteredSurveyList("normal");
      const hardList = this.getFilteredSurveyList("hard");
      const mjList = this.getFilteredSurveyList("mj");
      return [...easyList, ...normalList, ...hardList, ...mjList];
    },
    toNextQuestText() {
      return require(`@/assets/image/sgtest/do_test/labmem_text_btnnext_${this.$i18n.locale}.png`);
    },
    toFinishText() {
      return require(`@/assets/image/sgtest/do_test/labmem_text_btnfinish_${this.$i18n.locale}.png`);
    },
  },
  mounted() {
    this.currentId = this.randomSurveyList[0].id;
    this.sendLog({
      f3: "2",
      f4: "0",
      f5: "1",
    });
  },
  filters: {
    formatSerialAlphabet(value) {
      return String.fromCharCode(value + 65);
    },
  },
  methods: {
    translate,
    toNextQuest() {
      if (!this.selectedAnswerId) {
        this.noSelectTip = true;
        // 此属性的更改将重置超时
        this.tipTimeout++;
        if (this.tipTimeout > 3005) {
          this.tipTimeout = 3000;
        }
        return;
      }
      this.selectedAnswerList.push({
        qid: this.currentId,
        aid: this.selectedAnswerId,
        score: this.selectedAnswerId === this.currentQuestion.answer ? 10 : 0,
      });
      this.sendLog({
        f3: "2",
        f4: "1",
        f5: this.selectedAnswerId,
      });
      // 如果题目数量超过10，则不再继续
      if (this.currentQuestSeq >= 10) {
        this.$emit("toResult", this.selectedAnswerList);
        return;
      }
      this.currentQuestSeq++;
      const id = this.randomSurveyList[this.currentQuestSeq - 1].id;
      this.currentId = id;
      this.$emit("toNextQuest");
      this.sendLog({
        f3: "2",
        f4: "0",
        f5: this.currentQuestSeq,
      });
      this.selectedAnswerId = null;
    },
    selectOption(item) {
      this.selectedAnswerId = item.id;
      if (this.noSelectTip) {
        this.noSelectTip = false;
      }
    },
    sendLog(log) {
      this.$emit("sendLog", log);
    },
    getFilteredSurveyList(type) {
      // 类型对应的题目范围
      const { minId, maxId, count } = this.questIdMap[type];
      const filteredAnsweredQuest = [];
      const list = this.surveyList.filter((item) => {
        // 判断题目id是否在范围内
        if (item.id >= minId && item.id <= maxId) {
          // 判断题目是否已经回答过
          if (this.answeredQuest.includes(item.id)) {
            filteredAnsweredQuest.push(item.id);
          } else {
            return item;
          }
        }
        return null;
      });
      // 如果题目数量不足，则返回已回答过的题目
      while (list.length < count) {
        // 随机取出已回答过的题目
        const randomIndex = this.randomNum(0, filteredAnsweredQuest.length - 1);
        const randomId = filteredAnsweredQuest[randomIndex];
        const randomQuest = this.surveyList.find((item) => item.id === randomId);
        list.push(randomQuest);
        // 从已回答过的题目中删除已取出的题目
        filteredAnsweredQuest.splice(randomIndex, 1);
      }
      // 随机取出题目
      const randomList = this.shuffleArray(list);
      // 返回随机后的前几位指定数量的题目
      return randomList.slice(0, count);
    },
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    },
  },
};
</script>
<style lang="scss" scoped>
.DoTest {
  position: relative;
  width: 100%;
  padding: 7rem 4rem 0;
  .serial_num_section {
    position: relative;
    margin-bottom: 1rem;
    .serial_num {
      position: relative;
      display: inline-block;
      height: 6.6rem;
      line-height: 6.5rem;
      font-size: 4rem;
      color: #171717;
      &::after {
        content: "";
        display: block;
        width: calc(100% - 1.6rem);
        height: 6.6rem;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        border: solid 1px #000000;
      }
    }
    .line {
      width: 26.1rem;
    }
    .expression {
      position: absolute;
      left: 15rem;
      top: 3.6rem;
      width: 11.1rem;
    }
  }
  .qustion {
    height: 35rem;
    // margin-bottom: 8rem;
    // min-height: 20rem;
    p {
      font-size: 3.2rem;
      line-height: 6.6rem;
      color: #626363;
      // &.en {
      //   line-height: 4.8rem;
      // }
    }
  }
  .options {
    margin-bottom: 1rem;
    .option {
      display: flex;
      align-items: center;
      height: 12rem;
      border: 1px solid #323232;
      margin-bottom: 2rem;
      background-color: rgba($color: #fff, $alpha: 0.3);
      &::before {
        left: 0;
        transform: translate(0, -50%);
      }
      &.active {
        &::before {
          display: block;
        }
      }
      &:active,
      &.active_effect {
        &::before {
          display: none !important;
        }
        &::after {
          display: none !important;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
      .serial_alphabet {
        flex-shrink: 0;
        width: 7.5rem;
        height: 100%;
        line-height: 12rem;
        border-right: 1px dashed #323232;
        text-align: center;
        font-size: 3.8rem;
        color: #1e1e1e;
      }
      .option_text {
        padding: 0 2.5rem;
        font-size: 3.8rem;
        line-height: 4.8rem;
        color: #1e1e1e;
      }
    }
  }
  .page_info_wrap {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 2rem;
    .page_info {
      width: 11rem;
      height: 4rem;
      line-height: 4rem;
      background-image: url("../../../assets/image/sgtest/do_test/bg_page.png");
      background-repeat: no-repeat;
      background-size: cover;
      font-size: 3rem;
      color: #cab17c;
      text-align: center;
      text-shadow: 0px 1px 1px #000, 1px 0px 1px #000, 0px -1px 1px #000, -1px 0px 1px #000;
    }
  }
  .btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40.2rem;
    height: 13.6rem;
    background-image: url("../../../assets/image/sgtest/do_test/labmem_btn_next.png");
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
    padding-bottom: 1rem;
    padding-right: 0.8rem;
    img {
      display: block;
    }
    .text {
      width: 23rem;
      &.finish {
        width: 26.5rem;
      }
    }
    .deco_01,
    .deco_02 {
      position: absolute;
      height: 13.6rem;
    }
    .deco_01 {
      left: 36rem;
      top: -3rem;
    }
    .deco_02 {
      left: -18rem;
      top: 4rem;
    }
  }
}
</style>
<style lang="scss">
.no_select_tip {
  z-index: 99 !important;
  top: 35.4rem !important;
  .v-snack__wrapper {
    border-radius: 0 !important;
    &.v-sheet {
      width: 53rem;
      height: 5rem;
      background-image: url("../../../assets/image/sgtest/do_test/labmem_frame_tips.png");
      background-repeat: no-repeat;
      background-size: cover;
      background-color: transparent;
      color: #ffffff;
      box-shadow: none !important;
      min-width: auto;
      min-height: auto;
      .v-snack__content {
        .no_select_tip_msg {
          height: 5rem;
          line-height: 5rem;
          font-size: 3rem;
          text-align: center;
        }
      }
    }
  }
}
</style>
